import * as React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import Layout from '../components/Layout';

const BannerPrincipal = loadable(() => import('../components/BannerPrincipal'));
const ComoTrabajamos = loadable(() => import('../components/ComoTrabajamos'));
const Footer = loadable(() => import('../components/Footer'));
const Header = loadable(() => import('../components/Header'));
const QuienesSomos = loadable(() => import('../components/QuienesSomos'));
const Tecnologia = loadable(() => import('../components/Tecnologia'));

const IndexPage = () => (
    <Layout>
        <Header />
        <BannerPrincipal />
        <Tecnologia />
        <ComoTrabajamos />
        <QuienesSomos />
        <Footer />
    </Layout>
);
export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
